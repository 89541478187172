import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Autocomplete, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { countryList } from "../../utils/countryList";
import regex from "../../utils/regex";
import {
  addAddress,
  saveValue,
  updateEntity,
  userDetailsSelector
} from "../../store/slices/applicationFormSlice";
import AutoCompleteLoader from "../Application/AutoCompleteLoader";
import { AddressStatusList, ERRORS_MESSAGE, getAbbreviationState, stateList, streetTypeList } from "../../constants";


const ManualAddress = React.memo(({ customer, entity, applicationId }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const customerId = customer?._id
  const entityId = entity?._id
  const { gettingAddressDetails } = useSelector(userDetailsSelector);
  // const isCurrentAddress = addresses?.length > 0 ? addresses.find((address) => address.status === ADDRESS_STATUS_TYPE.CURRENT) : false

  const [fieldErrors, setFieldErrors] = useState({
    unitNumber: "",
    // addressType: "",
    // fullAddress: "",
    city: "",
    postCode: "",
    // streetAddress: "",
    // addressLine2: "",
    streetNumber: "",
    streetType: "",
    street: "",
    state: "",
    country: "",
    monthsAt: "",
    yearsAt: "",
  })

  const [allValues, setAllValue] = useState({
    unitNumber: "",
    // addressType: "",
    // fullAddress: "",
    city: "",
    postCode: "",
    // streetAddress: "",
    // addressLine2: "",
    streetNumber: "",
    streetType: "",
    street: "",
    state: "",
    country: "Australia",
    // status: isCurrentAddress ? ADDRESS_STATUS_TYPE.PREVIOUS : ADDRESS_STATUS_TYPE.CURRENT, // it's will take automatically
    monthsAt: 0,
    yearsAt: 0,
    // customer:,
    // user:,
  });

  const validationSchema = Yup.object({
    unitNumber: Yup.string()
      .optional()
      .matches(regex.allowOnlyOptionalNumber, "Only numbers allowed.")
      .max(7, "Maximum of 7 characters"),
    status: Yup.string()
      .oneOf(AddressStatusList, "Please select valid status")
      .required("Status is required"),
    country: Yup.string()
      .oneOf(countryList, "Please select valid country")
      .required("Country is required"),
    state: Yup.string()
      .required("State is required")
      .min(2, "Minimum of 2 characters")
      .max(20, "Maximum of 20 characters"),
    street: Yup.string()
      .required("Street name is required")
      .min(2, "Minimum of 2 characters")
      .max(20, "Maximum of 20 characters"),
    streetType: Yup.string()
      // .oneOf(streetTypeList, "Please select valid street type")
      .required("Street type is required")
      .min(2, "Minimum of 2 characters")
      .max(20, "Maximum of 20 characters"),
    streetNumber: Yup.string()
      .required("Street number is required")
      .min(1, "Minimum of 1 characters")
      .max(15, "Maximum of 15 characters"),
    city: Yup.string()
      .required("Suburb is required")
      .min(2, "Minimum of 2 characters")
      .max(20, "Maximum of 20 characters"),
    postCode: Yup.string()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .required("PostCode is required")
      .min(4, "Minimum of 4 characters")
      .max(4, "Maximum of 4 characters"),
    monthsAt: Yup.number().required("monthsAt is required"),
    yearsAt: Yup.number().required("yearsAt is required"),
  });

  const validateField = async (fieldName, value) => {
    const name = fieldName.split("_")[0];
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (value, fieldName) => {
      let isValid = true;

      if (!isValid) {
        await validateField(fieldName, value);
        return;
      }

      setAllValue({ ...allValues, [fieldName]: value });
      await validateField(fieldName, value);
    },
    onBlurField: (value, name) => {
      console.log("Save to DB")
      console.log("name", name, "value", value)
      // setAllValue({ ...allValues, [name]: value });
    },
    checkAddressCompleteness: (address) => {
      for (let key in address) {
        if (key !== "unitNumber" && address.hasOwnProperty(key)) {
          if (key === "monthsAt" || key === "yearsAt") {
            return true;
          } else {
            if (!address[key]) {
              return false;
            }
          }
        }
      }
      return true;
    },
    saveAddress: () => {

      if (!handle.checkAddressCompleteness(allValues)) return

      const {
        unitNumber,
        city,
        postCode,
        streetNumber,
        streetType,
        street,
        state,
        country,
        monthsAt,
        yearsAt,
      } = allValues

      // const country = "Australia" // This should not be hardcoded it's dynamic for customer

      const addressParts = [
        (unitNumber ? `unit ${unitNumber}/` : '') + `${streetNumber} ${street} ${streetType}`,
        `${city} ${getAbbreviationState[state]} ${postCode}`,
        country
      ].filter(Boolean);

      const fullAddress = addressParts.join(", ");

      // const fullAddress = (unitNumber ? `unit ${unitNumber}/` : '') + `${streetNumber} ${street} ${streetType}, ${city} ${getAbbreviationState[state]} ${postCode}, ${country} `

      const dataToBeSave = {
        unitNumber: unitNumber || null,
        fullAddress: fullAddress,
        city: city,
        postCode: postCode,
        streetNumber: streetNumber,
        streetType: streetType,
        street: street,
        state: state,
        country: country,
        // status: isCurrentAddress ? ADDRESS_STATUS_TYPE.PREVIOUS : ADDRESS_STATUS_TYPE.CURRENT, // it's will take automatically
        monthsAt: monthsAt,
        yearsAt: yearsAt,
      }

      if (entityId) {
        const currentEntityAddress = entity?.addresses?.slice(-1)[0]

        dispatch(updateEntity({
          ...(currentEntityAddress && { _id: currentEntityAddress._id }),
          entityId,
          applicationId,
          address: dataToBeSave,
          isManualAddress: true, // it's manual address add/edit
        })).then((res) => {
          dispatch(saveValue({ isManualAddress: { show: false, entityId: null } }));

          if (res.error) {
            enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        })

      } else if (customerId) {
        dispatch(addAddress({
          applicationId,
          customerId,
          address: dataToBeSave,
          isManualAddress: true,
          isEmployerAddress: false
        })).then((res) => {
          dispatch(saveValue({ isManualAddress: { show: false, customerId: null } }));

          if (res.error) {
            enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });
      }
    }
  };

  const isAddressCompleted = handle.checkAddressCompleteness(allValues)

  // If country not Australia then state & street should be input text box
  const isAustraliaAddress = allValues?.country === "Australia"

  return (
    <Grid>
      <Grid container spacing={1}>
        <Grid container md={12} sm={12} xl={12} item spacing={1}>
          <Grid item md={2} sm={6} xs={6}>
            <TextField
              fullWidth
              id="unit-number-input"
              type="text"
              name="unitNumber"
              label="Unit number"
              variant="filled"
              size="small"
              value={allValues?.unitNumber}
              error={fieldErrors?.unitNumber}
              helperText={fieldErrors?.unitNumber}
              onChange={(event) => handle.onChangeField(event?.target?.value, `unitNumber`)}
            // onBlur={() =>
            //   handle.onBlurField(allValues?.unitNumber, "unitNumber")
            // }
            />
          </Grid>

          <Grid item md={2} sm={6} xs={6}>
            <TextField
              fullWidth
              id="street-number-input"
              type="text"
              name="streetNumber"
              label="Street number"
              variant="filled"
              size="small"
              value={allValues?.streetNumber}
              error={fieldErrors?.streetNumber}
              helperText={fieldErrors?.streetNumber}
              onChange={(event) => handle.onChangeField(event?.target?.value, `streetNumber`)}
            // onBlur={() =>
            //   handle.onBlurField(allValues?.streetNumber, "streetNumber")
            // }
            />
          </Grid>

          <Grid item md={5} sm={6} xs={12}>
            <TextField
              fullWidth
              id="street-name-input"
              type="text"
              name="street"
              label="Street Name"
              variant="filled"
              size="small"
              value={allValues?.street}
              error={fieldErrors?.street}
              helperText={fieldErrors?.street}
              onChange={(event) => handle.onChangeField(event?.target?.value, `street`)}
            // onBlur={() =>
            //   handle.onBlurField(allValues?.street, "street")
            // }
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            {isAustraliaAddress ? (
              <Autocomplete
                autoComplete
                disableClearable
                loadingText={<AutoCompleteLoader />}
                value={allValues?.streetType}
                options={streetTypeList}
                onChange={(event, newValue) => {
                  handle.onChangeField(newValue || "", `streetType`);
                }}
                id="street-type-input"
                name="streetType"
                size="small"
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    error={fieldErrors?.streetType}
                    helperText={fieldErrors?.streetType}
                    size="small"
                    {...params}
                    label="Street Type"
                    variant="filled"
                  />
                )}
              />
            ) : (
              <TextField
                fullWidth
                id="streetType-input"
                type="text"
                name="streetType"
                label="Street type"
                variant="filled"
                size="small"
                value={allValues?.streetType}
                error={fieldErrors?.streetType}
                helperText={fieldErrors?.streetType}
                onChange={(event) => handle.onChangeField(event?.target?.value, `streetType`)}
              />
            )}
          </Grid>
        </Grid>

        <Grid container md={12} sm={12} xl={12} item spacing={1}>
          <Grid item md={2} sm={6} xs={6}>
            <TextField
              fullWidth
              id="post-code-input"
              type="text"
              name="post"
              label="PostCode"
              variant="filled"
              size="small"
              value={allValues?.postCode}
              error={fieldErrors?.postCode}
              helperText={fieldErrors?.postCode}
              onChange={(event) => handle.onChangeField(event?.target?.value, `postCode`)}
            // onBlur={() =>
            //   handle.onBlurField(allValues?.postCode, "postCode")
            // }
            />
          </Grid>

          <Grid item md={2.5} sm={6} xs={6}>
            <TextField
              fullWidth
              id="city-input"
              type="text"
              name="city"
              label="City"
              variant="filled"
              size="small"
              value={allValues?.city}
              error={fieldErrors?.city}
              helperText={fieldErrors?.city}
              onChange={(event) => handle.onChangeField(event?.target?.value, `city`)}
            // onBlur={() =>
            //   handle.onBlurField(allValues?.city, "city")
            // }
            />
          </Grid>

          <Grid item md={2.5} sm={6} xs={12}>
            {isAustraliaAddress ? (
              <FormControl
                fullWidth
                size="small"
                variant="filled"
                error={fieldErrors?.state}
              >
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  id="state-input"
                  value={allValues?.state}
                  label="State"
                  error={fieldErrors?.state}
                  onChange={(event) => {
                    handle.onChangeField(event.target.value || "", `state`);
                  }}
                >
                  {stateList?.length > 0 && stateList.map((i) => (
                    <MenuItem value={i} key={i}>{i}</MenuItem>
                  ))}
                </Select>
                {fieldErrors?.state && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {fieldErrors?.state}
                  </FormHelperText>
                )}
              </FormControl>
            ) : (
              <TextField
                fullWidth
                id="state-input"
                type="text"
                name="state"
                label="State"
                variant="filled"
                size="small"
                value={allValues?.state}
                error={fieldErrors?.state}
                helperText={fieldErrors?.state}
                onChange={(event) => handle.onChangeField(event?.target?.value, `state`)}
              />
            )}
          </Grid>

          {!entityId && (
            <Grid item md={2} sm={6} xs={12}>
              <Autocomplete
                autoComplete
                disableClearable
                loadingText={<AutoCompleteLoader />}
                value={allValues?.country}
                options={countryList}
                onChange={(event, newValue) => {
                  handle.onChangeField(newValue || "", `country`);
                }}
                id="country-type-input"
                name="country"
                size="small"
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    error={fieldErrors?.country}
                    helperText={fieldErrors?.country}
                    size="small"
                    {...params}
                    label="Country"
                    variant="filled"
                  />
                )}
              />
            </Grid>
          )}

          {!entityId && (
            <>
              <Grid item md={1.5} sm={6} xs={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="filled"
                  error={fieldErrors?.yearsAt}
                >
                  <InputLabel id="years-at-label">Years at</InputLabel>
                  <Select
                    labelId="years-at-label"
                    id="years-at-input"
                    value={allValues?.yearsAt}
                    label="Years at"
                    error={fieldErrors?.yearsAt}
                    onChange={(event) => {
                      handle.onChangeField(event?.target?.value, `yearsAt`)
                    }}
                  >
                    {[...Array(31).keys()].map((i) => (
                      <MenuItem value={i} key={i}>{i}</MenuItem>
                    ))}
                  </Select>
                  {fieldErrors?.yearsAt && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {fieldErrors?.yearsAt}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item md={1.5} sm={6} xs={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="filled"
                  error={fieldErrors?.monthsAt}
                >
                  <InputLabel id="months-at-label">Months at</InputLabel>
                  <Select
                    labelId="months-at-label"
                    id="months-at-input"
                    value={allValues?.monthsAt}
                    label="Months at"
                    error={fieldErrors?.monthsAt}
                    onChange={(event) => {
                      handle.onChangeField(event?.target?.value, `monthsAt`)
                    }}
                  >
                    {[...Array(13).keys()].map((i) => (
                      <MenuItem value={i} key={i}>{i}</MenuItem>
                    ))}
                  </Select>
                  {fieldErrors?.monthsAt && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {fieldErrors?.monthsAt}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </>
          )}

          {/* Special for Entity address : Entity address not require years at and months at */}
          {entityId && (
            <Grid item md={3} sm={3}>
              <Button
                variant="outlined"
                size="small"
                disabled={!isAddressCompleted || gettingAddressDetails}
                onClick={handle.saveAddress}
                style={{ marginTop: "10px" }}
              >
                {gettingAddressDetails ? 'Saving...' : 'Save Address'}
              </Button>
            </Grid>
          )}

        </Grid>

        {!entityId && (
          <Grid container md={12} sm={12} xl={12} item spacing={1}>
            <Grid item md={3} sm={3}>
              <Button
                variant="outlined"
                size="small"
                disabled={!isAddressCompleted || gettingAddressDetails}
                onClick={handle.saveAddress}
                style={{ marginTop: "10px" }}
              >
                {gettingAddressDetails ? 'Saving...' : 'Save Address'}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default ManualAddress;